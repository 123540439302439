<template>
  <div class="q-pa-md">
    <q-timeline color="green-4">
      <template
        v-for="(item, idx) in items">
        <q-timeline-entry
          :key="idx"
          :heading="item.heading" :class="{ 'q-pb-xs': item.heading }"
          :icon="item.icon">
          <template v-if="item.heading">
            <b>{{item.headLabel}}</b>
          </template>
          <template v-if="item.title" v-slot:title>
            <b 
              class="cursor-pointer"
              v-on:click="item.visible = !item.visible">{{item.title}}</b>
            <!-- <v-list-item 
              v-ripple="{ class: 'success--text' }" 
              class="cursor-pointer"
              v-on:click="item.visible = !item.visible">
              <v-list-item-title class="q-pb-lg"><b>{{item.title}}</b></v-list-item-title>
            </v-list-item> -->
          </template>
          <template v-if="item.subtitle" v-slot:subtitle>
            {{item.subtitle}}
          </template>
          <q-slide-transition v-if="!item.heading">
            <div v-show="item.visible">
              <component
                :is="item.component"
                :param="item.param"
              />
            </div>
          </q-slide-transition>
        </q-timeline-entry>
      </template>
    </q-timeline>
  </div>
</template>

<script>
export default {
  name: 'moc-onepage',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        
      }),
    },
  },
  data() {
    return {
      editable: true,
      visible: true,
      items: [
        { heading: true, headLabel: '발의', },
        { title: '판정 체크리스트', visible: true, component: () => import(`${'./judgeCheckList.vue'}`), param: { disabled: true, } },
        { title: '변경요청', visible: true, component: () => import(`${'./mocRequest.vue'}`), param: { disabled: true, } },
        { title: '검토항목', visible: true, component: () => import(`${'./reviewItem.vue'}`), param: { disabled: true, } },
        { heading: true, headLabel: '검토(위원회)', },
        { title: '위원회/검토자', visible: true, component: () => import(`${'./committeeNreviewer.vue'}`), param: { disabled: true, } },
        { title: '위원회개최', visible: true, component: () => import(`${'./mocMeeting.vue'}`), param: { disabled: true, } },
        { title: '검토', visible: true, component: () => import(`${'./mocReview.vue'}`), param: { disabled: true, } },
        { title: '승인', visible: true, component: () => import(`${'./mocApproval.vue'}`), param: { disabled: true, } },
        { heading: true, headLabel: '실시', },
        { title: 'PSM Tracking', visible: true, component: () => import(`${'./taskProceeding.vue'}`), param: { disabled: true, } },
        { heading: true, headLabel: 'Punch list', },
        { title: '요청 목록', visible: true, component: () => import(`${'@/pages/sop/ibm/imprTab.vue'}`), param: { disabled: true, } },
      ]
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
